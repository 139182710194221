.title {
  display: flex;  
  flex-flow: column wrap;
  font-weight: bold;
  /*text-align: center;*/
  width: 60%;
  margin: 0 auto;
};


.candidates {
  display: flex;  
  flex-flow: column wrap;
  font-weight: bold;
  /*text-align: center;*/
  width: 60%;
  /*justify-content: center;*/
  margin: 0 auto;
};


